const config = {
  apiKey: 'AIzaSyBnvWdr0lj0Eqa50h2veH9e5kqk_yKZG9s',
  authDomain: 'game-subscribe-db.firebaseapp.com',
  databaseURL: 'https://game-subscribe-db.firebaseio.com',
  projectId: 'game-subscribe-db',
  storageBucket: 'game-subscribe-db.appspot.com',
  messagingSenderId: '180038707918',
  appId: '1:180038707918:web:7fbccc8890be20a9f5be9f',
};

export default config;
